import { AfterViewInit, Component, EventEmitter, forwardRef, Host, Injector, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChange } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, FormGroupName, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SubscriptSizing } from '@angular/material/form-field';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { isValueInOptionValidator } from './is-value-in-option-validator';

@Component({
    selector: 'app-mat-autocomplete',
    templateUrl: './mat-auto-complete.component.html',
    styleUrls: ['./mat-auto-complete.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteControlComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AutocompleteControlComponent
        }
    ]
})
export class AutocompleteControlComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() formName: string = "";
    
    
    /**
     * The key that you want to set as the value of the form. 
     * For example, if the object has 'code' and 'name' {code: 'AP00', name: 'Advance Pmnt'}, 
     * if you provide the word 'code', then the form will have a value of 'AP00'
     */
    @Input() key: string = "";

    /**
     * Key to be filtered by when searching. Use a comma for multiple keys.
     */
    @Input() filter: string = "";

    // data
    @Input() options: any[] = [];

    // Limit of search result
    @Input() limit: number | undefined;

    /**
     * If displayFunction is provided, use display function
     * otherwise, will use 'key' 
     */
    @Input() displayFunction!: (option: any) => string;
    @Output() selectionChange = new EventEmitter();

    @Input() subscriptSizing: SubscriptSizing = "fixed";

    /**
     * Value of this form
     * if option is {'code': '00100', 'name': 'test'}
     * and key is 'code'
     * value will be option.code which is string '00100'
     */
    value: string = '';

    disabled: boolean = false;
    onChange: (value: string) => void = () => { };
    onTouched: () => void = () => { };

    filtredOptions: any[] = [];
    searchControl!: FormControl;

    constructor() {
    }

    ngOnInit() {
        this.filtredOptions = this.options.slice(0, this.limit);
        this.searchControl = new FormControl("");

        this.searchControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((searchValue) => {
                if (typeof searchValue === 'string') {
                    this.onChange(searchValue);
                    this.onTouched();
                    this.selectionChange.next(searchValue);
                }

                this.filtredOptions = this._filter(searchValue).slice(0, this.limit);
            });
    }

    // https://stackoverflow.com/questions/44731894/get-access-to-formcontrol-from-the-custom-form-component-in-angular
    ngAfterViewInit(): void {
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes["options"]) {
            if (changes["options"].currentValue) {
                this.filtredOptions = this.options.slice(0, this.limit);
            }
        }
    }

    private _filter(searchValue: string): string[] {
        if (typeof searchValue !== 'string') {
            return this.options;
        }

        let filterKeys: string[] = [];
        if (this.filter && this.filter.length > 0) {
            filterKeys = this.filter.split(",");
        }

        return this.options.filter((option) => {
            if (filterKeys.length > 0) {
                let value: string = "";
                for (let key of filterKeys) {
                    value += String(option[key]);
                }
                return value.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
            }
            else {
                const value = String(option[this.key]).toLocaleLowerCase();
                return value.includes(searchValue.toLocaleLowerCase());
            }
        });
    }

    displayFunctionForOption(option: any) {
        // If display function is provided, use display function
        if (this.displayFunction) {
            return this.displayFunction(option);
        }
        // Otherwise use the specified key
        return option[this.key];
    }

    displayWith() {
        if (this.displayFunction) {
            return this.displayFunction;
        }
        return (option: any) => option[this.key];
    }

    writeValue(value: any): void {
        if (value) {
            this.value = value;
            const matchedOption = this.options.find((i) => i[this.key] === value);
            if (matchedOption) {
                this.searchControl.patchValue(matchedOption);
            }
        }
        else {
            this.value = '';
            this.searchControl.patchValue(value);
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.searchControl.disable();
        }
        else {
            this.searchControl.enable();
        }
    }

    onSelected(event: MatAutocompleteSelectedEvent) {
        const selectedOption = event.option.value;
        const value = selectedOption[this.key];
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.selectionChange.next(value);
    }

    // https://netbasal.com/adding-integrated-validation-to-custom-form-controls-in-angular-dc55e49639ae
    validate(control: AbstractControl): ValidationErrors | null {
        const formControl = control as FormControl;
        if (!(formControl instanceof AbstractControl)) {
            return null;
        }

        const hasRequiredValidator = formControl.hasValidator(Validators.required);
        if (hasRequiredValidator) {
            this.searchControl.setValidators(Validators.required);
            this.searchControl.setValidators(isValueInOptionValidator(this.key, this.options));
            this.searchControl.updateValueAndValidity();

            const value = control.value;
            if (!value) {
                return {
                    required: true
                };
            }
            return null;
        }
        else {
            this.searchControl.removeValidators(Validators.required);
            this.searchControl.updateValueAndValidity();
            return null;
        }
    }

    onBtnClearClick() {
        this.value = "";
        this.searchControl.patchValue(this.value);
        this.onChange("");
        this.onTouched();
        this.selectionChange.next("");
    }
}
