import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { firstValueFrom } from "rxjs";
import { APIResponse } from "../../../api/common/models/api-response.model";
import { FWUResponseDto } from "../../../api/q2o/request/services/submit-q2o/fwu-fwo-dollar-link.response";
import { SaveFwuFwoDollarLinkRequest, SaveFwuFwoDollarLinkDetail } from "../../../api/q2o/request/services/submit-q2o/save-fwu-fwo-dollar-ink.request";
import { SubmitQ2OService } from "../../../api/q2o/request/services/submit-q2o/submit-q2o.service";
import { AlertService } from "../../../services/utilities/alert.service";
import { selectRequestIdAndProcessId } from "../../../store/selectors/create-q2o-request.selector";
import { setHaveBookForwardFlag } from "../../../store/actions/create-q2o-request.actions";
import { GetSapBankDropdownResponse } from "../../../api/q2o/request/models/confirm-quotation-dropdown.model";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DecimalPipe } from "@angular/common";
import { FormatService } from "../../../services/utilities/format.service";

interface FWURequestFormArray {
    fwuRequests: FormArray<FormGroup<FWURequestFormGroup>>;
}

interface FWURequestFormGroup {
    status: FormControl<string | null>;
    fwdId: FormControl<string | null>;
    objective: FormControl<string | null>;
    bank: FormControl<string | null>;
    estimateRecievedDate: FormControl<Date | null>;
    forwardType: FormControl<string | null>;
    forwardTypeDesc: FormControl<string | null>; // For display
    rate: FormControl<number | null>;
    totalRequest: FormControl<number | null>;
    isUnBook: FormControl<boolean | null>;

    // Not in the form but important
    groupNo: FormControl<number | null>;
    currency: FormControl<string | null>;
    direction: FormControl<string | null>;
    comCurrency: FormControl<string | null>;
}

interface DollarLinkFormGroup {
    requestDollarLink: FormControl<boolean | null>;
    status: FormControl<string | null>;
    fwdId: FormControl<string | null>;
    objective: FormControl<string | null>;
    bank: FormControl<string | null>;
    estimatePaymentDate: FormControl<Date | null>;
    forwardType: FormControl<string | null>;
    forwardTypeDesc: FormControl<string | null>;  // For display
    currency: FormControl<string | null>;
    rate: FormControl<number | null>;
    // requestAmount: FormControl<number | null>;
    requestAmount: FormControl<string | null>;

    // Not in the form but important
    direction: FormControl<string | null>;
    comCurrency: FormControl<string | null>;
}

@Component({
    selector: 'app-fwu-fwd-table',
    templateUrl: './fwu-fwd.table.component.html',
    styleUrl: './fwu-fwd.table.component.scss'
})
export class FwuFwdTableComponent implements OnInit {
    fwdFwuForm!: FormGroup<FWURequestFormArray>;
    dollarLinkForm!: FormGroup<DollarLinkFormGroup>;

    fwdData!: FWUResponseDto;
    showNoBookingMessage: boolean = false;
    
    isLoading: boolean = true;
    @Output() finishedLoadingEvent = new EventEmitter<boolean>();

    constructor(
        private submitQ2OService: SubmitQ2OService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
        private fb: FormBuilder,
        private store: Store,
        private decimalPipe: DecimalPipe,
        private formatService: FormatService,
    ) {
    }

    ngOnInit() {
        this.initializeForm();
        this.fetchData();
    }

    private initializeForm() {
        this.fwdFwuForm = this.fb.group<FWURequestFormArray>({
            fwuRequests: new FormArray<FormGroup<FWURequestFormGroup>>([])
        });

        this.dollarLinkForm = this.getDefaultDollarLinkForm();
    }

    get fwuRequests() {
        return this.fwdFwuForm.get("fwuRequests") as FormArray<FormGroup<FWURequestFormGroup>>;
    }

    get isRequestDollarLink() {
        return this.dollarLinkForm.value?.requestDollarLink === true;
    }

    onCheckDollarLink(event: MatCheckboxChange) {
        // If dollar link is checked, replace Unbook rates with dollar link's rate
        // otherwise, revert to original rate
        const isChecked = event.source.checked === true;
        for (let group of this.fwdData.fwu_fwd_groups) {
            const fgs = this.getFormGroupsByGroupNo(group.group_no);
            let idx = 0;
            for (let fg of fgs) {
                const isUnbook = fg.getRawValue().isUnBook;
                if (isUnbook) {
                    const dollarLinkRate = this.fwdData.dollar_link_dropdown_data.indicative_rate;
                    const originalRate = group.data[idx].rate;
                    fg.patchValue({
                        rate: isChecked ? dollarLinkRate : originalRate
                    });
                }
                idx++;
            }
        }
    }

    private getDefaultFwuFrom() {
        return this.fb.group<FWURequestFormGroup>({
            status: new FormControl(null, Validators.required),
            fwdId: new FormControl(null, Validators.required),
            objective: new FormControl(null),
            bank: new FormControl(null, Validators.required),
            estimateRecievedDate: new FormControl(null, Validators.required),
            forwardType: new FormControl(null, Validators.required),
            forwardTypeDesc: new FormControl(null),
            rate: new FormControl(null, Validators.required),
            totalRequest: new FormControl(null, Validators.required),
            isUnBook: new FormControl(null, Validators.required),

            groupNo: new FormControl(null, Validators.required),
            currency: new FormControl(null, Validators.required),
            direction: new FormControl(null, Validators.required),
            comCurrency: new FormControl(null, Validators.required)
        });
    }

    private getDefaultDollarLinkForm() {
        return this.fb.group<DollarLinkFormGroup>({
            requestDollarLink: new FormControl(false),
            status: new FormControl({ value: null, disabled: true }, Validators.required),
            fwdId: new FormControl({ value: null, disabled: true }, Validators.required),
            objective: new FormControl(null),
            bank: new FormControl(null, Validators.required),
            estimatePaymentDate: new FormControl(null, Validators.required),
            forwardType: new FormControl({ value: null, disabled: true }, Validators.required),
            forwardTypeDesc: new FormControl(null),
            currency: new FormControl({ value: null, disabled: true }, Validators.required),
            rate: new FormControl({ value: null, disabled: true }, Validators.required),
            requestAmount: new FormControl(null, Validators.required),

            direction: new FormControl(null, Validators.required),
            comCurrency: new FormControl(null, Validators.required)
        });
    }

    private async fetchData() {
        this.store.dispatch(setHaveBookForwardFlag(false));
        this.showNoBookingMessage = false;
        try {
            const checkFlagResult = await firstValueFrom(this.submitQ2OService.checkFlagAndUpdateStatus());
            this.isLoading = false;
            this.finishedLoadingEvent.next(true);
            if (checkFlagResult.isSuccess) {
                if (!checkFlagResult.data.do_fwu_flow) {
                    this.spinner.hide();
                    this.showNoBookingMessage = true;
                    return;
                }
                this.getForwardData();
            }
            else {
                this.spinner.hide();
                this.alertService.info(checkFlagResult.message);
            }
        }
        catch (err: any) {
            this.isLoading = false;
            this.spinner.hide();
            this.alertService.technicalError(err?.error?.message);
        }
    }

    private getForwardData() {
        this.submitQ2OService.getFwuFwoDollarLink().subscribe({
            next: (res) => {
                this.spinner.hide();
                this.fwdData = res.data;

                if (this.fwdData.fwu_fwd_groups.length == 0) {
                    this.showNoBookingMessage = true;
                }
                else {
                    this.store.dispatch(setHaveBookForwardFlag(true));
                }

                this.patchForm(res);
            },
            error: (err) => {
                this.spinner.hide();
                this.alertService.errorWithPrefix('loading fwd data');
            }
        });
    }

    private patchForm(res: APIResponse<FWUResponseDto>) {
        for (let group of res.data.fwu_fwd_groups) {
            for (let item of group.data) {
                const form = this.getDefaultFwuFrom();
                form.patchValue({
                    status: item.status,
                    fwdId: item.fwd_id,
                    objective: item.objective,
                    bank: item.bank,
                    estimateRecievedDate: item.date,
                    forwardType: item.fwd_type,
                    forwardTypeDesc: item.fwd_type_desc,
                    rate: item.rate,
                    totalRequest: item.total,
                    isUnBook: item.is_unbook,
                    groupNo: group.group_no,
                    currency: group.currency,
                    direction: item.direction,
                    comCurrency: item.com_cur
                });
                this.fwuRequests.push(form);
            }
        }

        if (res.data.show_dollar_link) {
            this.dollarLinkForm.patchValue({
                status: "Unbook",
                fwdId: "-",
                forwardType: "02",
                forwardTypeDesc: "Dollar Link",
                currency: res.data.show_dollar_link_currency,
                rate: res.data.dollar_link_dropdown_data.indicative_rate,
                direction: "02",
                comCurrency: "THB"
            });
        }
    }

    // Wtf are we doing here?
    // This form has a unique structure, where tables are grouped by currency and direction from the backend.
    // For each table, if the status is 'Unbooked', the form group must be editable.
    // Ideally, we require an 'array of form arrays' to accomplish this (looping through groups, then through their data).
    // However, we utilize only one form array, differentiating them by group number.
    getFormGroupsByGroupNo(groupNo: number) {
        return this.fwuRequests.controls.filter((c) => c.value.groupNo === groupNo);
    }

    getFormControlValue(groupNo: number, index: number, key: keyof FWURequestFormGroup) {
        const formGroups = this.getFormGroupsByGroupNo(groupNo);
        return formGroups[index].controls[key].value;
    }

    getFormControlDateValue(groupNo: number, index: number, key: keyof FWURequestFormGroup) {
        const formGroups = this.getFormGroupsByGroupNo(groupNo);
        return formGroups[index].controls[key].value as Date | null;;
    }

    getFormControlNumberValue(groupNo: number, index: number, key: keyof FWURequestFormGroup) {
        const formGroups = this.getFormGroupsByGroupNo(groupNo);
        return formGroups[index].controls[key].value as number | null;;
    }

    getDollarLinkFormControlValue(key: keyof DollarLinkFormGroup) {
        return this.dollarLinkForm.controls[key].value;
    }

    // Then, we save fwu
    // from fwdFwuForm forms and dollarLinkForm
    // we get ones with status = 'Unbook' only
    async saveFWU() {
        this.spinner.show();
        const reqBody = await this.prepareSaveFWURequest();

        this.submitQ2OService.saveFwuFwoDollarLink(reqBody).subscribe({
            next: (res) => {
                this.spinner.hide();
                if (res.isSuccess) {
                    this.alertService.successWithPrefix('saved FWD.<br>You can proceed to the next step');
                    this.fwdFwuForm.disable();
                    this.dollarLinkForm.disable();
                }
                else {
                    this.alertService.error(res.message);
                }
            },
            error: (err) => {
                this.spinner.hide();
                this.alertService.technicalError(err?.error?.message);
            }
        })
    }

    async prepareSaveFWURequest() {
        const { requestId, processId } = await firstValueFrom(this.store.select(selectRequestIdAndProcessId));

        const request: SaveFwuFwoDollarLinkRequest = {
            request_id: requestId,
            process_id: processId,
            fwu_fwo: [],
            dollar_link: []
        };

        // FWU
        const fwuFormValues = this.fwdFwuForm.getRawValue();
        for (let v of fwuFormValues.fwuRequests) {
            if (v.status === 'Unbook') {
                const detail: SaveFwuFwoDollarLinkDetail = {
                    com_cur: v.comCurrency ?? "",
                    foreign_cur: v.currency ?? "",
                    direction: v.direction ?? "",
                    rate: Number(v.rate) ?? -1,
                    amount: Number(v.totalRequest) ?? -1,
                    objective: v.objective,
                    date: moment(v.estimateRecievedDate).toISOString(),
                    bank: v.bank ?? "",
                    fwd_type: v.forwardType ?? ""
                };
                request.fwu_fwo.push(detail);
            }
        }

        // Dollar link
        const dollarLinkFormValue = this.dollarLinkForm.getRawValue();
        if (this.isRequestDollarLink && dollarLinkFormValue.status === 'Unbook') {
            const v = dollarLinkFormValue;
            const detail: SaveFwuFwoDollarLinkDetail = {
                com_cur: v.comCurrency ?? "",
                foreign_cur: v.currency ?? "",
                direction: v.direction ?? "",
                rate: Number(v.rate) ?? -1,
                // amount: Number(v.requestAmount) ?? -1,
                amount: this.formatService.thousandsSeparatorToNumber(v.requestAmount!) ?? -1,
                objective: v.objective,
                date: moment(v.estimatePaymentDate).toISOString(),
                bank: v.bank ?? "",
                fwd_type: v.forwardType ?? ""
            };
            request.dollar_link.push(detail);
        }

        return request;
    }

    get isFormValid() {
        if (this.isLoading) {
            return false;
        }

        if (this.isRequestDollarLink) {
            return this.fwdFwuForm.valid && this.dollarLinkForm.valid
        }
        else {
            return this.fwdFwuForm.valid;
        }
    }

    get haveForwardToBook() {
        return this.fwdData && (this.fwdData.show_dollar_link || this.fwdData.fwu_fwd_groups.length > 0);
    }

    getBankNameFromBankId(bankId: string | any, banks: GetSapBankDropdownResponse[]) {
        const bank = banks.find((i)=> i.bank_id === bankId);
        if(bank) {
            return bank.bank_name;
        }
        return bankId;
    }
}