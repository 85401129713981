import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";


export function isValueInOptionValidator(key: string, options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value === null || value === "") {
            return null;
        }

        let isValueInOptions: boolean = false;
        for (let opt of options) {
            if (opt[key] === value[key]) {
                isValueInOptions = true;
                break;
            }
        }

        if (isValueInOptions) {
            return null
        }

        return { valueNotInOptions: true };
    };
}